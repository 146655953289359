import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Token } from '../models/token.model';
import { BrowserDeviceViewProperties } from '../models/browser-device-view-properties.model';

@Injectable({
  providedIn: 'root',
})
export class AppStoreService {
  private _token = new BehaviorSubject<Token | null>(null);
  token$ = this._token.asObservable();

  private _errorMessage = new BehaviorSubject<string | undefined>(undefined);
  errorMessage$ = this._errorMessage.asObservable();

  // private _browserDeviceViewProperties = new BehaviorSubject<BrowserDeviceViewProperties | null>(null);
  // browserDeviceViewProperties$ = this._browserDeviceViewProperties.asObservable();

  private langSource = new BehaviorSubject<string>('hu');
  lang$ = this.langSource.asObservable();

  private languageListSource = new BehaviorSubject<string[]>(['hu']);
  languageList$ = this.languageListSource.asObservable();

  private _backendVersion = new BehaviorSubject<string>('');
  backendVersion$ = this._backendVersion.asObservable();

  private _backendHost = new BehaviorSubject<string>('');
  backendHost$ = this._backendHost.asObservable();

  private _backendBuildTime = new BehaviorSubject<string>('');
  backendBuildTime$ = this._backendBuildTime.asObservable();

  constructor() {}

  clearUser() {
    this.clearToken();
  }

  clearToken() {
    this._token.next(null);
  }

  clearAll() {
    this.clearUser();
    this.clearToken();
  }

  setToken(token: Token): void {
    this._token.next(token);
  }

  setErrorMessage(errorMessage?: string): void {
    this._errorMessage.next(errorMessage);
  }

  // setBrowserDeviceViewProperties(browserDeviceViewProperties: BrowserDeviceViewProperties): void {
  //   this._browserDeviceViewProperties.next(browserDeviceViewProperties);
  // }

  setLang(lang: string): void {
    this.langSource.next(lang);
  }

  setLanguageList(languageList: string[]): void {
    this.languageListSource.next(languageList);
  }

  setBackendVersion(backendVersion: string | null): void {
    this._backendVersion.next(backendVersion ?? '');
  }

  setBackendHost(backendHost: string | null): void {
    this._backendHost.next(backendHost ?? '');
  }

  setBackendBuildTime(backendBuildTime: string | null): void {
    this._backendHost.next(backendBuildTime ?? '');
  }

  get token(): Token | null {
    return this._token.getValue();
  }

  get tokenUserName(): string {
    if(this._token.getValue()){
      return this._token.getValue()!.iss;
    }
    return '';
  }

  // get browserDeviceViewProperties(): BrowserDeviceViewProperties | null {
  //   return this._browserDeviceViewProperties.getValue();
  // }

  get lang(): string {
    return this.langSource.getValue();
  }

  get languageList(): string[] {
    return this.languageListSource.getValue();
  }
}
